<template>
  <fade-transition :duration="150">
    <div
      v-if="isModalVisible"
      class="modal-wrapper__overlay hide-scrollbar"
      :class="contentWrapperDynamicClass"
    >
      <focus-trap
        :active="isFocusTrapActive"
        :initial-focus="getInitialFocusElement"
        :return-focus-on-deactivate="returnFocusOnDeactivate"
        :escape-deactivates="false"
        @deactivate="onFocusTrapDeactivate"
        @activate="onFocusTrapActivate"
      >
        <div
          ref="modalFocusableWrapper"
          tabindex="-1"
          v-on-clickaway="onClickAway"
          :class="CONTENT_WRAPPER_CLASS_NAME"
          :style="contentWrapperStyle"
          role="dialog"
          aria-modal="true"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"
        >
          <a-scrollable-content>
            <slot :close-modal="closeModal" :props="props" />
          </a-scrollable-content>
          <div v-if="closeButton" class="modal-wrapper__close-wrapper">
            <div v-focusable @click.stop="closeModal" class="close-icon" />
          </div>
        </div>
      </focus-trap>
    </div>
  </fade-transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { directive as onClickaway } from 'vue-clickaway'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import AScrollableContent from 'shared/AScrollableContent'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { KEY_CODE } from '@fmpedia/enums'
import {
  hasParentWith,
  waitForElAppearsInDom,
  getFirstFocusableDescendant
} from '@fmpedia/helpers'

export const TRANSITIONS = {
  FADE: 'fade',
  SLIDE_UP: 'slide-up'
}

export const MODAL = {
  AUTH_FORM: 'auth-form',
  CONTRIBUTOR_WELCOME: 'contributor-welcome',
  SUGGEST_A_TERM: 'suggest-a-term',
  ANNOUNCEMENT_CONFIRMATION: 'announcement-confirmation',
  SEND_ME_AN_OFFER: 'send-me-an-offer',
  IMAGE_UPLOADER: 'image-uploader',
  VIDEO_UPLOADER: 'video-uploader',
  TWITTER_CARD: 'twitter-card',
  RENEW_COMPANY_FEATURES: 'renew-company-features',
  DEACTIVATE_ACCOUNT: 'deactivate-account',
  DEACTIVATE_ACCOUNT_DIRECTORY: 'deactivate-account-directory',
  MAKE_YOUR_ACCOUNT_BETTER: 'make-your-account-better',
  SUCCESS_UPDATE_PROFILE_INFO: 'success-update-profile-info',
  SUCCESS_BECOME_AN_AUTHOR: 'success-become-an-author',
  DELETE_CONTRIBUTOR_POST_CONFIRMATION: 'delete-contributor-post-confirmation',
  REACTIVATE_USER_CONFIRMATION: 'reactivate-user-confirmation',
  USER_IS_ALREADY_ACTIVE: 'user-is-already-active',
  IMAGE_TYPE_SELECTOR: 'image-type-selector',
  NEWSLETTER_FORM: 'newsletter-form',
  CONFIRM_PAGE_LEAVE: 'confirm-page-leave',
  SUCCESS_CREATE_COMPANY_VIDEO_CR: 'success-create-company-video-cr',
  SUCCESS_CREATE_COMPANY_NEWS_CR: 'success-create-company-news-cr',
  SUCCESS_CREATE_CONTRIBUTOR_POST_CR: 'success-create-contributor-post-cr',
  SUCCESS_CREATE_COMPANY_CR: 'success-create-company-cr'
}

const TRANSITION_VALUES = Object.values(TRANSITIONS)

const CONTENT_WRAPPER_CLASS_NAME = 'modal-wrapper__content-wrapper'

export default {
  name: 'AModalWrapper',
  mixins: [hydrationHelpers],
  components: {
    AScrollableContent,
    FocusTrap: () => import('focus-trap-vue').then(module => module.FocusTrap)
  },
  directives: { onClickaway },
  props: {
    modalName: propValidator([PROP_TYPES.STRING]),
    width: propValidator([PROP_TYPES.NUMBER], false),
    closeButton: propValidator([PROP_TYPES.BOOLEAN], false, false),
    closeOnClickAway: propValidator([PROP_TYPES.BOOLEAN], false, true),
    initialFocus: propValidator([PROP_TYPES.FUNCTION], false),
    showScrollOnClose: propValidator([PROP_TYPES.BOOLEAN], false, true),
    transition: propValidator(
      [PROP_TYPES.STRING],
      false,
      TRANSITIONS.FADE,
      value => TRANSITION_VALUES.includes(value)
    ),
    transparent: propValidator([PROP_TYPES.BOOLEAN], false, false),
    parentClassesToPreventClickaway: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => []
    ),
    parentIdsToPreventClickaway: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => []
    ),
    closeModalOnEscape: propValidator([PROP_TYPES.BOOLEAN], false, true),
    activateFocusTrapOnModalOpen: propValidator(
      [PROP_TYPES.BOOLEAN],
      false,
      true
    ),
    /**
     * Please note that if modal is destroyed on route change, this prop
     * will not work.
     */
    keepOpenOnRouteChange: propValidator([PROP_TYPES.BOOLEAN], false, false),
    allowOpeningSameFormMultipleTimes: propValidator(
      [PROP_TYPES.BOOLEAN],
      false,
      false
    )
  },
  data() {
    return {
      CONTENT_WRAPPER_CLASS_NAME,
      isModalVisible: false,
      isFocusTrapActive: false,
      isClickedInsideModalWindow: false,
      props: {}
    }
  },
  computed: {
    ...mapGetters({
      isAppleBrowser: 'isAppleBrowser',
      mobileBottomPadding: 'mobileBottomPadding',
      isModalVisibleByName: 'modal/isModalVisibleByName',
      isPageNavigationInProgress: 'isPageNavigationInProgress',
      activeModalName: 'modal/activeModalName'
    }),
    returnFocusOnDeactivate() {
      /**
       * We manually return focus to activator if it's provided
       */
      return !this.activatorSelector
    },
    activatorSelector() {
      return this.props && this.props.activatorSelector
    },
    contentWrapperStyle() {
      if (!this.$_hydrationHelpers_isLayoutMobile) {
        return {
          width: `${this.width}px`
        }
      }

      return { paddingBottom: `${this.mobileBottomPadding}px` }
    },
    contentWrapperDynamicClass() {
      return {
        transparent: this.transparent
      }
    }
  },
  watch: {
    isPageNavigationInProgress(newVal) {
      if (!newVal || this.keepOpenOnRouteChange) return

      this.closeModal()
    },
    activeModalName: {
      immediate: true,
      handler(newVal) {
        if (!process.client) return

        if (newVal !== this.modalName) {
          this.removeEscListener()
        } else {
          this.registerEscListener()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setModalToOpenState: 'modal/setModalToOpenState',
      setModalToCloseState: 'modal/setModalToCloseState'
    }),
    activateFocusTrap() {
      this.isFocusTrapActive = true
    },
    deactivateFocusTrap() {
      this.isFocusTrapActive = false
    },
    setFocusOnActivator() {
      if (!this.activatorSelector) return

      const activatorEl = document.querySelector(this.activatorSelector)

      if (!activatorEl || !activatorEl.focus) return

      activatorEl.focus()
    },
    onClickAway(e) {
      const target = e.target
      const isTargetInsideContentWrapper = hasParentWith(target, {
        className: CONTENT_WRAPPER_CLASS_NAME
      })
      const isClickawayShouldBePrevented = [
        ...this.parentClassesToPreventClickaway.map(parentClass =>
          hasParentWith(target, { className: parentClass })
        ),
        ...this.parentIdsToPreventClickaway.map(parentId =>
          hasParentWith(target, { id: parentId })
        )
      ].some(r => r)

      if (
        isTargetInsideContentWrapper ||
        isClickawayShouldBePrevented ||
        this.$helper.isElementCaptchaOverlay(e.target)
      )
        return

      if (this.closeOnClickAway) {
        this.closeModal()
      }
    },
    showScrollBar() {
      this.$_hydrationHelpers_showScroll()
      this.$helper.getTopStickyElement().classList.remove('nofit')
    },
    showScrollHandler() {
      if (this.$_hydrationHelpers_isLayoutMobile) {
        this.showScrollBar()
      } else {
        /**
         * this timeout is used to wait when fade transition is complete.
         * In other case two scroll appears for some moment and move content left.
         * Transition delay is set assets/scss/utils/_animations.scss
         */
        setTimeout(() => {
          this.showScrollBar()
        }, 200)
      }
    },
    closeModal() {
      if (this.isClickedInsideModalWindow) {
        this.isClickedInsideModalWindow = false
        return
      }

      if (this.showScrollOnClose) this.showScrollHandler()

      if (!this.isModalVisible) return

      this.isModalVisible = false
      this.deactivateFocusTrap()
      this.setModalToCloseState(this.modalName)

      this.$emit('close-modal', this.props)
    },
    onFocusTrapDeactivate() {
      this.$emit('focus-trap-deactivate')

      waitForElAppearsInDom({ selector: this.activatorSelector }).then(
        this.setFocusOnActivator
      )
    },
    /**
     * Returns an element to focus in a given modal.
     *
     * By default, we focus on the first focusable modal content
     * descendant, but this behavior can be changed by passing the
     * initialFocus function.
     * @returns {*}
     */
    getInitialFocusElement() {
      const initialFocusElement = this.initialFocus && this.initialFocus()

      return (
        initialFocusElement ||
        getFirstFocusableDescendant(this.$refs.modalFocusableWrapper)
      )
    },
    onFocusTrapActivate() {
      this.$emit('focus-trap-activate')
    },
    onMouseDown() {
      this.isClickedInsideModalWindow = true
    },
    onMouseUp() {
      this.isClickedInsideModalWindow = false
    },
    registerEscListener() {
      document.addEventListener('keydown', this.onDocumentKeyDown)
    },
    removeEscListener() {
      document.removeEventListener('keydown', this.onDocumentKeyDown)
    },
    onDocumentKeyDown(event) {
      if (!this.isModalVisible || event.keyCode !== KEY_CODE.ESCAPE) {
        return
      }

      this.$emit('escape')

      if (this.closeModalOnEscape) {
        this.closeModal()
      }
    }
  },
  mounted() {
    this.$bus.$on(`open-modal-${this.modalName}`, (props = {}) => {
      if (
        !this.allowOpeningSameFormMultipleTimes &&
        this.isModalVisibleByName(this.modalName)
      ) {
        console.log(
          `The modal with the name ${this.modalName} is already opened.`
        )
        return
      }

      this.props = props
      this.isModalVisible = true

      if (this.activateFocusTrapOnModalOpen) {
        this.activateFocusTrap()
      }

      this.setModalToOpenState(this.modalName)
      this.$_hydrationHelpers_hideScroll()
      this.$helper.getTopStickyElement().classList.add('nofit')
      this.$emit('open-modal', props)
    })

    this.$bus.$on(`close-modal-${this.modalName}`, () => this.closeModal())
  },
  beforeDestroy() {
    if (this.isModalVisible) {
      this.showScrollBar()
    }
    this.$bus.$off(`open-modal-${this.modalName}`)
    this.removeEscListener()
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: $z-index-modal-wrapper;

  .modal-wrapper__content-wrapper {
    position: relative;
    display: flex;
    max-height: 100%;
    margin: auto;
    background: #fff;
    overflow: hidden;

    @include on-laptop-max {
      position: relative;
    }

    @include mobile {
      height: 100%;
      width: 100%;
    }
  }

  &.transparent .modal-wrapper__content-wrapper {
    background: transparent;
  }

  .modal-wrapper__close-wrapper {
    position: absolute;
    width: 100%;
    height: 35px;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;

    .close-icon {
      right: 10px;
      top: 10px;
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: top 500ms;
}

.slide-up-enter,
.slide-up-leave-to {
  top: 100vh;
}
</style>
