import * as general from '@/utils/mixins/general'
import * as googleBanners from '@/utils/mixins/banners'
import dataRefsInDom from '@/utils/mixins/data-refs-in-dom'
import sideBannersMovement from '@/utils/mixins/side-banners-movement'
import * as personalProfile from '@/utils/mixins/personal-profile'
import { oneSignal } from '@/utils/mixins/one-signal'
import { prefilledForm } from '@/utils/mixins/prefilled-form'
import { userDetails } from '@/utils/mixins/user-details'
import pendingAuthActions from '@/utils/mixins/pending-auth-actions'
import pendingAuthActionsListenersFactory from '@/utils/mixins/pending-auth-actions-listeners'
import { lazyLoadModal } from '@/utils/mixins/lazy-load-modal'
import refDimensions from '@/utils/mixins/ref-dimensions'
import { countryInfo } from '@/utils/mixins/country-info'
import { authError } from '@/utils/mixins/auth-error'
import { componentValidation } from '@/utils/mixins/component-validation'
import dataValidator from '@/utils/mixins/data-validator'
import mediaCenter from '@/utils/mixins/media-center'
import companyProfile from '@/utils/mixins/company-profile'
import { runMethodOnLoginLogout } from '@/utils/mixins/run-method-on-login-logout'
import { captchaLazyLoad } from '@/utils/mixins/captcha-lazy-load'
import refreshDataHandler from '@/utils/mixins/refresh-data-handler'
import companyFeatures from '@/utils/mixins/company-features'
import signalr from '@/utils/mixins/signalr'
import sourceTabs from '@/utils/mixins/source-tabs'
import documentVisibility from '@/utils/mixins/document-visibility'
import oneTrust from '@/utils/mixins/one-trust'
import actionsOnQueryParams from '@/utils/mixins/actions-on-query-params'
import trackSearch from '@/utils/mixins/track-search'
import ebookDataFetch from '@/utils/mixins/ebook-data-fetch'
import authTracking from '@/utils/mixins/auth-tracking'
import disqusCommentCountMixinFactory from '@/utils/mixins/disqus-comment-count-mixin-factory'
import internetConnectionState from '@/utils/mixins/internet-connection-state'
import oneTrustListener from '@/utils/mixins/one-trust-listener'
import ampClass from '@/utils/mixins/amp-class'
import focusTrapState from '@/utils/mixins/focus-trap-state'

export default {
  ...general,
  ...googleBanners,
  dataRefsInDom,
  sideBannersMovement,
  oneSignal,
  prefilledForm,
  personalProfile,
  userDetails,
  pendingAuthActions,
  pendingAuthActionsListenersFactory,
  lazyLoadModal,
  refDimensions,
  countryInfo,
  authError,
  componentValidation,
  dataValidator,
  mediaCenter,
  companyProfile,
  runMethodOnLoginLogout,
  refreshDataHandler,
  companyFeatures,
  signalr,
  sourceTabs,
  documentVisibility,
  captchaLazyLoad,
  oneTrust,
  actionsOnQueryParams,
  trackSearch,
  ebookDataFetch,
  authTracking,
  disqusCommentCountMixinFactory,
  internetConnectionState,
  oneTrustListener,
  ampClass,
  focusTrapState
}
